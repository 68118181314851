import * as React from "react"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"
import AddressType from "../../../../model/AddressType"
import { FC } from "react"
import FieldWithIsValidType from "model/src/model/FieldWithIsValidType"

const CountryField: FC<FormFieldProps<AddressType>> & FieldWithIsValidType = ({
  onChange,
  onBlur,
  state,
  validate,
  disabled,
}) => {
  return (
    <OutlinedInput
      name="country"
      onChange={onChange}
      value={state.country}
      label="Country"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={CountryField.isValid(state)}
      disabled={disabled}
    />
  )
}

CountryField.isValid = state => {
  return state.country
}

export default CountryField
