import * as React from "react"
import AddressType from "../../../../model/AddressType"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"
import { FC } from "react"
import FieldWithIsValidType from "model/src/model/FieldWithIsValidType"

const LastNameField: FC<FormFieldProps<AddressType>> & FieldWithIsValidType = ({
  onChange,
  onBlur,
  state,
  validate,
}) => {
  return (
    <OutlinedInput
      name="lastName"
      onChange={onChange}
      value={state.lastName}
      label="Last Name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={LastNameField.isValid(state)}
    />
  )
}

LastNameField.isValid = state => {
  return state.lastName
}

export default LastNameField
