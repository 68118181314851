import * as React from "react"
import { FC, useState } from "react"
import { Icon } from "semantic-ui-react"
import styled from "styled-components"
import FormFieldProps from "../../FormFieldProps"
import OutlinedInput from "../../OutlinedInput"
import LinkButton from "../../../button/LinkButton"
import { linkBlue } from "../../../../constants/colors"
import AddressType from "../../../../model/AddressType"
import FieldWithIsValidType from "model/src/model/FieldWithIsValidType"

const Button = styled(LinkButton)`
  text-align: left;
  margin-bottom: 5px;

  i {
    color: ${linkBlue};
    margin-right: 10px;
  }
`

const Address2Field: FC<FormFieldProps<AddressType>> & FieldWithIsValidType = ({
  onChange,
  onBlur,
  state,
}) => {
  const [show, setShow] = useState<boolean>(false)

  return show ? (
    <OutlinedInput
      name="address2"
      onChange={onChange}
      onBlur={onBlur}
      value={state.address2}
      label="Address Line 2"
      validate={false}
      type="text"
      isValid={Address2Field.isValid(state)}
      autoComplete="address-line2"
    />
  ) : (
    <Button onClick={() => setShow(true)}>
      <Icon name="plus circle" size="small" />
      Add Address Line 2
    </Button>
  )
}

Address2Field.isValid = state => {
  return true
}

export default Address2Field
