import { clearDuplicateImages } from "./imageUtils"
import ProductMetrics from "../model/ProductMetrics"
import { getMean, getStandardDeviation, getZScore } from "./mathUtil"
import { formatDateIso, getBusinessDaysAhead, getDaysAgo, getDaysAhead } from "./dateUtil"
import { isANumber } from "./generalUtil"
import {
  BasketItemType,
  ImageType,
  ProductTaxonomyType,
  ProductType,
  VariantType,
  VendorType,
  VisibilityType,
} from "model"
import { getPriceRange } from "model"

export const getProductMeta = (product: ProductType) => {
  return {
    vendorName: product.vendor?.name,
    vendorSlug: product.vendor?.slug,
    vendorAcceptsGiftNotes: product.vendor?.acceptsGiftNotes,
    vendorDeliveryBusinessDays: product.vendor?.deliveryBusinessDays,
    vendorDeliveryNotice: product.vendor?.deliveryNotice,
    vendorLastEventDate: product.vendor?.lastEventDate,
    vendorLastShipmentDate: product.vendor?.lastShipmentDate,
    vendorResumeShipmentDate: product.vendor?.resumeShipmentDate,
    brandName: product.brand?.name,
    brandSlug: product.brand?.slug,
    shippingClass: product.shippingClass,
    soonestDeliveryDate: product.soonestDeliveryDate,
  }
}

export const findSingleTaxonomyLeaf = (taxonomies: ProductTaxonomyType[]): ProductTaxonomyType => {
  const leaves = findTaxonomyLeaves(taxonomies)
  leaves.sort((a, b) => b.priority - a.priority)
  return leaves.length > 0 ? leaves[0] : null
}

export const findTaxonomyLeaves = (taxonomies: ProductTaxonomyType[]): ProductTaxonomyType[] => {
  taxonomies.forEach(tax => {
    tax.parent = taxonomies.find(t => t.slug === tax.parentSlug)
  })

  const leafNodes = taxonomies
    // .filter(tax => tax.isLive)
    .filter(taxI => !taxonomies.some(taxJ => taxJ.parentSlug === taxI.slug))

  const liveLeafNodes = leafNodes
    .filter(node => node.isLive)
    .filter(node => node.slug !== "uncategorized")

  return liveLeafNodes
}

export const getAllImagesSmall = (product: ProductType): ImageType[] => {
  return clearDuplicateImages(
    [
      product.imageSmall || product.image,
      ...product.gallery,
      ...(product.variants ? product.variants.map(v => v.image) : []),
    ].filter(i => i)
  )
}

export const sortProductsByProperty = (
  products: ProductType[],
  propertyName: string
): ProductType[] => {
  const sortedProducts = [...products]

  sortedProducts.sort((a, b) => {
    if (a[propertyName] > b[propertyName]) {
      return -1
    }
    if (b[propertyName] > a[propertyName]) {
      return 1
    }
    return 0
  })

  return sortedProducts
}

export const getSalesMetrics = (products: ProductType[]): ProductMetrics => {
  const values = products
    .map(p => {
      const range = getPriceRange(p)
      return p.recentSales * range[0]
    })
    .filter(v => v > 0)
  return {
    standardDeviation: getStandardDeviation(values),
    mean: getMean(values),
  }
}

export const getSalesZScore = (product: ProductType, metrics: ProductMetrics): number => {
  const range = getPriceRange(product)
  const value = product.recentSales * range[0]
  return getZScore(value, metrics.mean, metrics.standardDeviation)
}

export const getAgeMetrics = (products: ProductType[]): ProductMetrics => {
  const values = products.map(p => getDaysAgo(p.date))
  return {
    standardDeviation: getStandardDeviation(values),
    mean: getMean(values),
  }
}

export const getAgeZScore = (product: ProductType, metrics: ProductMetrics): number => {
  return getZScore(getDaysAgo(product.date), metrics.mean, metrics.standardDeviation)
}

export const getVendorFromItem = (item: BasketItemType) => {
  const undefinedVendor: VendorType = {
    slug: "undefined",
    name: "Undefined",
    acceptsGiftNotes: false,
    deliveryBusinessDays: 5,
  }

  const date = new Date()
  const hivedVendor = {
    slug: "hived",
    name: "Hived Park Delivery",
    acceptsGiftNotes: false,
    deliveryBusinessDays: date.getHours() < 15 ? 0 : 1,
    deliveryDays: date.getHours() < 15 ? 0 : 1,
  }

  let vendor = item.product.vendor.slug ? item.product.vendor : undefinedVendor

  if (
    item.variant &&
    item.variant.attributes.some(attribute => attribute.value === "What 3 Words Park Delivery")
  ) {
    vendor = hivedVendor
  }

  return vendor
}

export const filterByVisibility = (products: ProductType[], visibilityType: VisibilityType) => {
  return products.filter(product => {
    const visibility = product.visibility || []
    return product.vendor?.isLive && visibility.includes(visibilityType)
  })
}

export const isProductFullyOutOfStock = (product: ProductType): boolean => {
  if (product.variants && product.variants.length > 0) {
    return product.variants.every(variant => variant.stockStatus === "OUT_OF_STOCK")
  }

  return product.stockStatus === "OUT_OF_STOCK"
}

export const compareStockStatus = (a: ProductType, b: ProductType): number => {
  const aOutOfStock = isProductFullyOutOfStock(a)
  const bOutOfStock = isProductFullyOutOfStock(b)

  if (!aOutOfStock && bOutOfStock) {
    return -1
  }
  if (aOutOfStock && !bOutOfStock) {
    return 1
  }
  return 0
}

export const getExpectedDeliveryDate = (vendor: VendorType): string => {
  const startDate: Date = isVendorPaused(vendor) ? new Date(vendor.resumeShipmentDate) : new Date()

  const expectedDeliveryDate: Date = !isNaN(vendor.deliveryDays)
    ? getDaysAhead(startDate, vendor.deliveryDays)
    : getBusinessDaysAhead(startDate, vendor.deliveryBusinessDays || 5)

  return expectedDeliveryDate.toLocaleDateString()
}

export const isVendorPastEventDate = (vendor: VendorType): boolean => {
  return vendor.lastEventDate && vendor.lastEventDate < formatDateIso(new Date())
}

export const isVendorPaused = (vendor: VendorType): boolean => {
  const currentDate = formatDateIso(new Date())
  return (
    vendor.lastShipmentDate &&
    vendor.resumeShipmentDate &&
    currentDate > vendor.lastShipmentDate &&
    currentDate < vendor.resumeShipmentDate
  )
}

export const isVendorFrozen = (vendor: VendorType): boolean => {
  return isVendorPaused(vendor) && vendor.freezeOrders
}
