import * as React from "react"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"
import { validEmailFormat } from "../../../../constants/validation"
import ContactDetailsType from "../../../../model/ContactDetailsType"
import { FC } from "react"
import FieldWithIsValidType from "model/src/model/FieldWithIsValidType"

const EmailField: FC<FormFieldProps<ContactDetailsType>> & FieldWithIsValidType = ({
  onChange,
  onBlur,
  state,
  validate,
}) => {
  return (
    <OutlinedInput
      name="email"
      onChange={onChange}
      value={state.email}
      label="Email"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={EmailField.isValid(state)}
    />
  )
}

EmailField.isValid = ({ email }) => {
  return email && validEmailFormat.test(email)
}

export default EmailField
