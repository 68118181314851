import { isRunTime } from "../util/generalUtil"
import BasketType from "../model/BasketType"
import { ProductTaxonomyType, ProductType, VariantType, getPriceRange, parsePrice } from "model"

declare global {
  interface Window {
    dataLayer: any
  }
}

export const prePageLoad = () => {
  track({
    ecommerce: {
      impressions: undefined,
      click: undefined,
      detail: undefined,
      remove: undefined,
      add: undefined,
    },
  })
}

export const productImpressions = (
  index: number,
  products: ProductType[],
  collectionName: string
) => {
  const impressions = products.map((product, i) => {
    const range = getPriceRange(product)
    return {
      name: product.name,
      id: product.productId,
      price: range[1].toFixed(2),
      brand: product.brand?.name,
      category: getCategory(product)?.name,
      list: collectionName,
      position: index + i + 1,
    }
  })

  track({
    ecommerce: {
      currencyCode: "GBP",
      impressions,
    },
  })
}

export const loadMoreProducts = () => {
  track({ event: "load-more-products-click" })
}

export const productClick = (product: ProductType, position: number) => {
  const range = getPriceRange(product)
  track({
    event: "productClick",
    ecommerce: {
      currencyCode: "GBP",
      impressions: undefined,
      click: {
        products: [
          {
            name: product.name,
            id: product.productId,
            price: range[1].toFixed(2),
            brand: product.brand?.name,
            category: getCategory(product)?.name,
            position: position,
          },
        ],
      },
    },
  })
}

export const productView = (product: ProductType) => {
  const range = getPriceRange(product)
  track({
    value: range[0],
    items: getItemsFromProduct(product),
    ecommerce: {
      currencyCode: "GBP",
      detail: {
        products: [
          {
            name: product.name,
            id: product.productId,
            price: range[1].toFixed(2),
            brand: product.brand?.name,
            category: getCategory(product)?.name,
          },
        ],
      },
    },
  })
}

export const variantSelected = (variant: VariantType) => {
  track({
    event: "variantSelected",
    value: parsePrice(variant.price),
    items: [{ id: variant.variantId, google_business_vertical: "retail" }],
  })
}

export const addToBasket = (product: ProductType, variant: VariantType, quantity: number) => {
  const range = getPriceRange(product, variant ? [variant] : null, quantity)
  track({
    event: "addToCart",
    ecommerce: {
      currencyCode: "GBP",
      detail: undefined,
      remove: undefined,
      add: {
        products: [
          {
            name: variant?.name ? variant.name : product.name,
            id: product.productId,
            price: range[1].toFixed(2),
            brand: product.brand?.name,
            category: getCategory(product)?.name,
            quantity,
          },
        ],
      },
    },
  })
}

export const removeFromBasket = (product: ProductType) => {
  const range = getPriceRange(product)
  track({
    event: "removeFromCart",
    ecommerce: {
      currencyCode: "GBP",
      detail: undefined,
      add: undefined,
      remove: {
        products: [
          {
            name: product.name,
            id: product.productId,
            brand: product?.brand,
            price: range[1].toFixed(2),
          },
        ],
      },
    },
  })
}

export const startCheckout = (basket: BasketType) => {
  track({
    event: "checkout",
    ecommerce: {
      click: undefined,
      detail: undefined,
      remove: undefined,
      add: undefined,
      currencyCode: "GBP",
      checkout: {
        actionField: { step: 1 },
        products: basket.items.map(item => ({
          name: item.product.name,
          id: item.product.productId,
          brand: item.product?.brand?.name,
          price: getPriceRange(
            item.product,
            item.variant ? [item.variant] : null,
            item.quantity
          )[1].toFixed(2),
          quantity: item.quantity,
        })),
      },
    },
  })
}

export const orderSuccess = (orderId: number, basket: BasketType) => {
  track({
    event: "purchaseComplete",
    ecommerce: {
      currencyCode: "GBP",
      detail: undefined,
      checkout: undefined,
      purchase: {
        actionField: {
          id: orderId,
          affiliation: "Online Store",
          revenue: basket.total.toFixed(2),
          shipping: basket.shippingMethod.total.toFixed(2),
        },
        products: basket.items.map(item => ({
          name: item.product.name,
          id: item.product.productId,
          brand: item.product?.brand?.name,
          price: getPriceRange(
            item.product,
            item.variant ? [item.variant] : null,
            item.quantity
          )[1].toFixed(2),
          quantity: item.quantity,
        })),
      },
    },
  })
}

export const corporateEnquiry = (value: number) => {
  track({
    event: "corporate-contact",
    ecommerce: undefined,
    estimated_value: value,
  })
}

export const conversion = (eventName: string, value: number) => {
  track({
    event: eventName,
    ecommerce: undefined,
    estimated_value: value,
  })
}

export const platformBookADemo = () => {
  track({
    event: "platform-book-demo",
    ecommerce: undefined,
  })
}

export const modalOpened = () => {
  track({
    event: "modal_opened",
    ecommerce: undefined,
  })
}

export const platformGetStarted = () => {
  track({
    event: "platform-get-started",
    ecommerce: undefined,
  })
}

const getItemsFromProduct = (product: ProductType): any[] => {
  if (product.variants && product.variants.length > 0) {
    return product.variants.map(variant => ({
      id: variant.variantId,
      google_business_vertical: "retail",
    }))
  } else {
    return [{ id: product.productId, google_business_vertical: "retail" }]
  }
}

const track = object => {
  if (canTrack()) {
    window.dataLayer.push(object)
  }
}

const canTrack = (): boolean => {
  return isRunTime() && window.dataLayer
}

const getCategory = (product: ProductType): ProductTaxonomyType => {
  return product?.categories && product.categories.length > 0 ? product.categories[0] : undefined
}
