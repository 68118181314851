import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { darkBorderGrey } from "../../../constant/colors"
import { formatDate } from "../../../utils/dateUtil"
import { offGrey } from "../../constants/colors"
import FlipImage from "../../components/image/FlipImage"
import { formatPrice, parsePrice } from "model"

const Item = styled.div`
  margin-bottom: 10px;
  display: flex;
`

const ImageContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin-bottom: 0;
`

const Middle = styled.div`
  flex: 1;
  margin-left: 20px;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
`

const Name = styled.div`
  font-size: 0.8em;
  font-weight: bold;
`

const Quantity = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  color: white;
  background-color: ${darkBorderGrey};
  margin: 0;
  border-radius: 20px;
  z-index: 1;
  font-size: 0.6em;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: arial;
`

const Image = styled.div`
  border: 1px solid ${offGrey};
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
`

const Attributes = styled.div`
  font-size: 0.8em;
`

const getGiftCardAttributes = ({ giftCard: { name, email, schedule, date, note } }) => [
  {
    name: "From",
    value: name,
  },
  {
    name: "Recipient",
    value: email,
  },
  {
    name: "Send",
    value: schedule === "now" ? "Now" : formatDate(date),
  },
  {
    name: "Note",
    value: note,
  },
]

const CheckoutItem = ({ item }) => {
  const { variant, product, quantity } = item
  const { slug, name, image, price } = product
  const attributes =
    slug === "eco-friendly-gift-card"
      ? getGiftCardAttributes(item)
      : variant
      ? variant.attributes
      : []
  return (
    <Item>
      <ImageContainer>
        <Quantity>{quantity}</Quantity>
        <Image>
          <FlipImage image={image} />
        </Image>
      </ImageContainer>
      <Middle>
        <Name>{name}</Name>
        {attributes && (
          <Attributes>
            {attributes.map((attribute, i) => (
              <span>
                {attribute.value} {i < attributes.length - 1 && "/ "}
              </span>
            ))}
          </Attributes>
        )}
      </Middle>
      <Right>{formatPrice(parsePrice(price) * quantity)}</Right>
    </Item>
  )
}

export default CheckoutItem
