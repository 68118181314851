import { ImageType } from "model"

export const clearDuplicateImages = (images: ImageType[]): ImageType[] => {
  const sourceUrls = []

  return images.filter(image => {
    if (sourceUrls.includes(image.src)) {
      return false
    } else {
      sourceUrls.push(image.src)
      return true
    }
  })
}
