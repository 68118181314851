import ShippingMethod, { DEFAULT_SHIPPING_METHOD } from "./ShippingMethod"
import Coupon from "./Coupon"
import { BasketItemType } from "model"

export default interface BasketType {
  hash: string
  key: string
  subTotal: number
  subTotalTax: number
  discountTotal: number
  discountTax: number
  items: BasketItemType[]
  shippingMethod: ShippingMethod
  total: number
  totalTax: number
  coupons: Coupon[]
}

export const DEFAULT_BASKET: BasketType = {
  hash: "",
  key: "",
  subTotal: 0,
  subTotalTax: 0,
  discountTotal: 0,
  discountTax: 0,
  total: 0,
  totalTax: 0,
  items: [],
  shippingMethod: {
    ...DEFAULT_SHIPPING_METHOD,
  },
  coupons: [],
}
