import * as React from "react"
import FormFieldProps from "../../FormFieldProps"
import OutlinedInput from "../../OutlinedInput"
import SearchInput from "../../SearchInput"
import AddressType from "../../../../model/AddressType"
import { FC } from "react"
import FieldWithIsValidType from "model/src/model/FieldWithIsValidType"

const Address1Field: FC<FormFieldProps<AddressType>> & FieldWithIsValidType = ({
  onChange,
  onBlur,
  state,
  validate,
}) => {
  const handleSearchChange = (name, value, selected) => {
    onChange(name, value)
    if (selected) {
      const option = state.addressOptions.find(option => option.address1 === value)
      if (option && option.address2) {
        onChange("address2", option.address2)
      }
    }
  }

  const hasOptions = state.addressOptions.length > 0
  return hasOptions ? (
    <div>
      <SearchInput
        name="address1"
        onChange={handleSearchChange}
        onBlur={onBlur}
        label="Address Line 1"
        value={state.address1}
        validate={validate}
        isValid={Address1Field.isValid(state)}
        options={state.addressOptions.map(option => option.address1)}
        autoComplete="address-line1"
      />
    </div>
  ) : (
    <OutlinedInput
      name="address1"
      onChange={onChange}
      onBlur={onBlur}
      label="Address Line 1"
      value={state.address1}
      validate={validate}
      isValid={Address1Field.isValid(state)}
    />
  )
}

Address1Field.isValid = state => {
  return state.address1
}

export default Address1Field
