import searchInsights from "search-insights"
import { isProdEnv } from "../../config"
import { ProductType } from "model"

export const productClick = (product: ProductType, position: number, queryID?: string) => {
  if (isProdEnv() && queryID) {
    searchInsights("clickedObjectIDsAfterSearch", {
      index: "products",
      eventName: "Click product",
      queryID,
      objectIDs: [`${product.productId}`],
      positions: [position],
    })
  }
}

export const addToBasket = (product: ProductType, queryID?: string) => {
  if (isProdEnv() && queryID) {
    searchInsights("convertedObjectIDsAfterSearch", {
      index: "products",
      eventName: "Add to Basket",
      queryID,
      objectIDs: [`${product.productId}`],
    })
  }
}
