import * as React from "react"
import AddressType from "../../../../model/AddressType"
import OutlinedInput from "../../OutlinedInput"
import { lookupPostcode } from "../../../../../api/address"
import FormFieldProps from "../../FormFieldProps"
import { FC } from "react"
import FieldWithIsValidType from "model/src/model/FieldWithIsValidType"

const PostCodeField: FC<FormFieldProps<AddressType>> & FieldWithIsValidType = ({
  onChange,
  onBlur,
  state,
  validate,
}) => {
  const handleChange = (name, value) => {
    onChange(name, value)
    if (value.length > 4) {
      lookupPostcode(value).then(data => {
        if (data && data.addresses && data.addresses[0]) {
          onChange("city", data.addresses[0].city)
          onChange("postCode", formatPostCode(value))
          onChange("addressOptions", data.addresses)
        }
      })
    }
  }
  return (
    <OutlinedInput
      name="postCode"
      onChange={handleChange}
      onBlur={onBlur}
      label="Post Code"
      value={state.postCode}
      validate={validate}
      isValid={PostCodeField.isValid(state)}
    />
  )
}

const formatPostCode = postCode => {
  const parts = postCode.toUpperCase().match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/)
  parts.shift()
  return parts.join(" ")
}

PostCodeField.isValid = state => {
  return state.postCode
}

export default PostCodeField
